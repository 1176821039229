import { Component, Input, OnChanges, Output, EventEmitter, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { Api, ApiResponse, ApiRequest } from '@app/models/speedpost-data.model';
import { MethodIconComponent } from '@app/components/shared/method-icon/method-icon.component';
import { RequestSectionComponent } from '@app/components/body-section/request-section/request-section.component';
import { ResponseSectionComponent } from '@app/components/body-section/response-section/response-section.component';
import { Server, Response, Responses, RequestBody, Operation } from '@app/models/openapispec.model';
import { HttpResponse } from '@angular/common/http';
import { ProductionRequestSectionComponent } from '../request-section/production-request-section/production-request-section.component';
import { ProductionResponseComponent } from '../response-section/production-response/production-response.component';
import { FormDataModel, SharedService } from '@app/services/speedpost-api/shared.service';
import { ProductionTabComponent } from '../request-section/production-tab/production-tab.component';

@Component({
    selector: 'app-body-content',
    standalone: true,
    templateUrl: './body-content.component.html',
    styleUrl: './body-content.component.scss',
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MethodIconComponent,
        RequestSectionComponent,
        ResponseSectionComponent,
        BsDropdownModule,
        TabsModule,
        ProductionTabComponent,
        ProductionRequestSectionComponent,
        ProductionResponseComponent
    ]
})
export class BodyContentComponent implements OnChanges, OnInit{
  @Input() activeAPIObject: Api = null as unknown as Api;
  @Input() servers: Server[] = null as unknown as Server[];
  @Input() selectedServer = null as unknown as Server;
  @Input()
  keySecretIdValue!: { key: string; secretId: string; };
  @Input()
  contractDetails!: { key: string; secretId: string, name: string}[];
  @Input() isProdSubscribed: boolean = false;

  @Output() updateSelectedServerEvent = new EventEmitter<Server>();

  apiRequestExampleExists: boolean = true;
  apiResponseExampleExists: boolean = true;
  showResponse: boolean = false;
  responses: ApiResponse[] = [];
  request: ApiRequest = null as unknown as ApiRequest;
  allTabsFormValid: boolean = true;
  productionResponse!: HttpResponse<null>;
  showProdResponse: boolean = false;
  updatedParam!: {param: {key:string, value: string}[], paramType: string};
  updateContentType: string = '';
  isParamFormValid: boolean =  false;

  isHeaderFormValid: boolean = true;
  isBodyFormValid: boolean = true;
  isQueryParamFormValid: boolean = true;
  isPathParamFormValid: boolean = true;
  isResponseLoading: boolean = false;

  constructor(private sharedService: SharedService, private cdref: ChangeDetectorRef ){}

  ngOnInit(): void {
    this.allTabsFormValid = true;
  }

  ngOnChanges() {
    this.showResponse = false;
    this.showProdResponse = false;
    if (this.activeAPIObject && this.activeAPIObject.apiDetails) {
      this.initRequest(this.activeAPIObject.apiDetails);
      this.initResponses(this.activeAPIObject.apiDetails.responses);
    }
    this.cdref.detectChanges();
  }

  initRequest(requestData: Operation) {
    const apiRequestExampleExists = false;
    const [bodyMediaType] = requestData.requestBody? Object.keys(requestData.requestBody.content) : '';
    const bodyExamples = requestData.requestBody ? requestData.requestBody.content[bodyMediaType].examples : null;
    const exampleKeys = bodyExamples ? Object.keys(bodyExamples).map(exampleKey => {
      return exampleKey;
    }): null;
    const bodySchemaExists = requestData.requestBody? this.requestSchemaExists(bodyMediaType, requestData.requestBody) : false;
    const bodyExampleExists = requestData.requestBody? this.requestExampleExists(bodyMediaType, requestData.requestBody) : false;
    this.apiRequestExampleExists = apiRequestExampleExists || bodySchemaExists || bodyExampleExists || requestData.parameters? true : false;
    const headers = requestData.parameters?.filter(param => {
      return param.in === 'header'
    });
    const parameters = requestData.parameters?.filter(param => {
      return param.in !== 'header'
    })
    this.request = {
      description: requestData.description,
      parameters: parameters && parameters?.length > 0 ? parameters : null,
      bodyMediaType,
      bodyExampleExists,
      exampleKeys,
      bodyExamples,
      bodyExample: requestData.requestBody?.content[bodyMediaType].example,
      selectedExampleKey: exampleKeys ? exampleKeys[0]: null,
      security: requestData.security,
      headers,
      bodyDescription: requestData.requestBody?.description
    };
    console.log('Request object --- ', this.request);
  }

  initResponses(responsesData: Responses) {
    let apiResponseExampleExists = false;

    this.responses = Object.keys(responsesData).flatMap((statusCode) => {
      const responseDetails = responsesData[statusCode];
      const mediaTypes = responseDetails.content? Object.keys(responseDetails.content).map(mediaType => {
        return mediaType;
      }): null;
      const examples = mediaTypes && responseDetails.content ? responseDetails.content[mediaTypes[0]].examples : null;
      const exampleKeys = examples ? Object.keys(examples).map(exampleKey => {
        return exampleKey;
      }): null;
      const schemaExists = responseDetails ? true : false;
      const exampleExists = mediaTypes? this.responseExampleExists(mediaTypes, responseDetails) : false;
      apiResponseExampleExists = apiResponseExampleExists ||  exampleExists || schemaExists;

      return {
        statusCode,
        description: responseDetails.description,
        responseDetails: responseDetails,
        mediaTypes,
        selectedMediaType: mediaTypes && mediaTypes.length > 0 ? mediaTypes[0] : null,
        exampleExists: mediaTypes? this.responseExampleExists(mediaTypes, responseDetails) : false,
        exampleKeys,
        examples,
        selectedExampleKey: exampleKeys ? exampleKeys[0] : null,
        headers:  responseDetails.headers
      }
    });
    console.log('Response object --- ', this.responses);
    this.apiResponseExampleExists = apiResponseExampleExists;
  }

  onExecute() {
    this.showResponse = true;
  }

  updateSelectedServer(value: Server) {
    this.updateSelectedServerEvent.emit(value);
  }

  requestSchemaExists(mediaType: string, requestData: RequestBody): boolean {
    if (mediaType) {
      if(requestData.content[mediaType]) {
        return true;
      }
    }
    return false;
  }

  requestExampleExists(mediaType: string, requestData: RequestBody): boolean {
    if (mediaType) {
      if(requestData.content[mediaType].examples) {
        return true;
      } else if (requestData.content[mediaType].example) {
        return true;
      }
    }
    return false;
  }

  responseSchemaExists(mediaTypes: string[], responseDetails: Response): boolean {
    if (mediaTypes && mediaTypes.length > 0) {
      return true;
    } else if(responseDetails){
      return true;
    }
    return false;
  }

  responseExampleExists(mediaTypes: string[], responseDetails: Response): boolean {
    if (mediaTypes && mediaTypes.length > 0 && responseDetails.content) {
      if(responseDetails.content[mediaTypes[0]].examples) {
        return true;
      } else if (responseDetails.content[mediaTypes[0]].example) {
        return true;
      }
    }
    return false;
  }

  onEmittingTabData(event: FormDataModel){
    this.sharedService.setAllTabDetails(event);
  }

  prodFormValid(event: boolean){
    this.allTabsFormValid = event;
    this.cdref.detectChanges();
  }

  onParamFormValidEvent(event: boolean){
    this.isParamFormValid = event
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFetchingResponse(response: any){
    this.showProdResponse = true;
    this.productionResponse = response;
  }

  getUpdatedParams(event: {param: {key:string, value: string}[], paramType: string}){
      this.updatedParam = event;
  }

  updateSelectedContentType(event: string){
    this.sharedService.setContentType(event);
  }

  queryParamFormValid(event: boolean){
    this.isQueryParamFormValid = event;
  }

  pathParamFormValid(event: boolean){
    this.isPathParamFormValid = event;
  }

  headerFormValid(event: boolean){
    this.isHeaderFormValid = event;
  }

  bodyFormValid(event: boolean){
    this.isBodyFormValid = event;
  }

  isAllFormsValid(event: boolean){
    this.allTabsFormValid = event;
  }

  onLoadingResponse(event: boolean){
    this.isResponseLoading = event;
  }
}
