import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabDirective, TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
  BsModalRef,
  BsModalService,
  ModalModule,
  ModalOptions,
} from 'ngx-bootstrap/modal';

import { CodeEditorComponent } from '@app/components/shared/code-editor/code-editor.component';
import { KeyValueTableComponent } from '@app/components/shared/param-table/param-table.component';
import { ApiRequest } from '@app/models/speedpost-data.model';
import { ModalPopupComponent } from '@app/components/shared/modal-popup/modal-popup.component';

@Component({
  selector: 'app-request-section',
  standalone: true,
  imports: [CommonModule, TabsModule, CodeEditorComponent, KeyValueTableComponent, BsDropdownModule, ModalModule],
  templateUrl: './request-section.component.html',
  styleUrl: './request-section.component.scss',
  providers: [BsModalService]
})
export class RequestSectionComponent implements OnChanges{
  @Input() request: ApiRequest = null as unknown as ApiRequest;
  objectKeys = Object.keys;
  bsModalRef?: BsModalRef;
  activeTab!: string;

  constructor(
    private modalService: BsModalService,
  ){}

  ngOnChanges(): void {
    this.setDefaultTab();
  }
  
  updateSelectedExample(exampleKey: string) {
    this.request.selectedExampleKey = exampleKey;
  }

  updateSelectedExampleKeyDown(event: KeyboardEvent, exampleKey: string) {
    if (event.key === "Enter") {
      this.updateSelectedExample(exampleKey);
    }
  }

  openModalPopup(heading: string, description: string) {
    const title = heading + ' - Description';
    const initialState: ModalOptions = {
      class: 'modal-dialog-centered modal-lg i-modal',
      initialState: {
        title,
        data: description,
      },
      ignoreBackdropClick: true
    };
    this.bsModalRef = this.modalService.show(
      ModalPopupComponent,
      initialState,
    );
  }

  setDefaultTab(){
    let activeTab = "";
    const bodyTab = this.request?.bodyExamples || this.request?.bodyExample;
    const headersTab = this.request?.headers && this.request?.headers.length > 0;
    const paramTab = this.request && this.request?.parameters;

    if(this.request?.security && this.request.security.length > 0){
      activeTab = "authorization";
    }  else if(headersTab){
      activeTab = "headers";
    } else if(paramTab) {
      activeTab = "params";
    }else if(bodyTab){
      activeTab = "body";
    }
    this.activeTab = activeTab;
  }

  onTabSelect(event:TabDirective){
    if(event && event.id){
      this.activeTab = event?.id;
    }
  }
}
