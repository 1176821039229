<ng-container *ngIf="contractDetails">
    <div class="p-2 m-0 header-bottom">
        <div class="d-flex justify-content-between position-relative">
            <span class="specification-badge align-self-center">{{contractDetails.apiSpecificationType}} Specification</span>
            <button class="ms-3 btn btn-primary" (click)="onDownloadContract()">Download Contract</button>
        </div>
    </div>
    
    <div class="p-2 m-0 header-bottom">
        <div class="align-self-center">
            <span class="d-inline-block pe-2 m-0 fs__16">API Name: {{contractDetails.apiName}}</span>
            <span class="ps-2 pe-2 border-separator">API Version: v{{contractDetails.apiVersion}}</span>
        </div>
    </div>
</ng-container>