import { Injectable } from '@angular/core';
import * as xml2js from 'xml2js';

@Injectable({
  providedIn: 'root'
})
export class WsdlParserService {

  constructor() { }

  parseWsdl (wsdlString: string) {
    let res;
    const cleanedWsdlString = this.removeLeadingNonWhitespace(wsdlString);
    xml2js.parseString(cleanedWsdlString, { explicitArray: false }, (err, result) => {
      if (err) {
        console.log(err, 'Error parsing WSDL');
        res = 'Error parsing WSDL';
      } else {
        console.log(result);
        res = result;
      }
    });

    const builder = new xml2js.Builder();
    return builder.buildObject(res);

  }

  removeLeadingNonWhitespace(wsdlString: string): string {
    const trimmedString = wsdlString.trimStart();
    // Find the index of the first '<' which marks the start of an XML/HTML tag
    const firstTagIndex = trimmedString.indexOf('<');
    // If there's no '<' tag, return the string as is (this can happen for invalid content)
    if (firstTagIndex === -1) {
      return trimmedString;
    }
    const leadingContent = trimmedString.substring(0, firstTagIndex);
    // Check if leadingContent has any non-whitespace characters (like BOM or other invisible characters)
    if (leadingContent.trim().length > 0) {
      return trimmedString.substring(firstTagIndex);
    }
    return trimmedString;
  }
}
