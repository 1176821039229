<ng-container>
    <div class="body-section position-relative" *ngIf="activeAPIObject">
        <tabset>
            <tab id="static">
                <ng-template tabHeading><div class="heading text__bold fs__16">Static</div></ng-template>
                <div class="container mt-4 request-response-section">
                    <div class="d-flex align-items-center flex-nowrap mb-3">
                        <app-method-icon [methodName]="activeAPIObject.method" sizeCategory="bodyContent"></app-method-icon>
                        <div class="server-url flex-grow-1 d-flex"><span class="static-url d-inline-block">https://api.static.dac</span><span class="d-inline-block path-wrap">{{activeAPIObject.path}}</span></div>
                        <button class="btn btn-primary" *ngIf="activeAPIObject.apiDetails" (click)="onExecute()" [disabled]="!apiResponseExampleExists">Execute</button>
                    </div>
                    <ng-container>
                        <div  *ngIf="(apiRequestExampleExists || apiResponseExampleExists) && activeAPIObject.apiDetails; else noExample">
                            <app-request-section [request]="request"></app-request-section>
                            <app-response-section [showResponse]="showResponse"
                            [responses]="responses"></app-response-section>
                        </div>
                         <ng-template #noExample><div class="no-example p-3">No examples available to display. Please update the contract to view static responses.</div></ng-template>
                    </ng-container>
                </div>
            </tab>
            <!-- <tab id="sandbox">
                <ng-template tabHeading><div class="heading text__bold">Sandbox</div></ng-template>
                <div class="container mt-4 request-response-section" >
                    <div class="d-flex align-items-center flex-nowrap mb-3">
                        <app-method-icon [methodName]="activeAPIObject.method" sizeCategory="bodyContent"></app-method-icon>
                        <div class="server-url flex-grow-1 d-flex"><span class="static-url d-inline-block">https://api.sandbox.dac</span><span class="d-inline-block">{{activeAPIObject.path}}</span></div>
                        <button class="btn btn-primary" *ngIf="activeAPIObject.apiDetails" (click)="onExecute()" disabled>Execute</button>
                    </div>
                </div>
            </tab> -->
            <tab id="production" *ngIf="isProdSubscribed">
                <ng-template tabHeading><div class="heading text__bold fs__16">Production</div></ng-template>
                <div class="container mt-4 request-response-section">
                    <app-production-tab [activeAPIObj]="activeAPIObject" [prodRequest]="request"
                    [allTabsFormValidForProd]="allTabsFormValid" [servers]="servers" (emitResponseEvent)="onFetchingResponse($event)"
                    [addedParams]="updatedParam"
                    [headerForm]="isHeaderFormValid"
                    [bodyForm]="isBodyFormValid"
                    [queryParamForm]="isQueryParamFormValid"
                    [pathParamForm]="isPathParamFormValid"
                    (isLoadingEvent)="onLoadingResponse($event)"></app-production-tab>
                    <div class="ps-1">
                        <app-production-request-section [activeAPIObj]="activeAPIObject" [productionRequest]="request" [productionTabDetails]="contractDetails" 
                        (emitFormDataEvent)="onEmittingTabData($event)" (allFormValidEvent)="prodFormValid($event)" (emitParamsEvent)="getUpdatedParams($event)"
                        (emitContentType)="updateSelectedContentType($event)"
                        (queryFormValidEvent)="queryParamFormValid($event)"
                        (pathFormValidEvent)="pathParamFormValid($event)"
                        (headerFormValidEvent)="headerFormValid($event)"
                        (bodyFormValidEvent)="bodyFormValid($event)"></app-production-request-section>
                        @if (isResponseLoading) {
                            <div class="d-flex justify-content-center position-absolute spinner-wrapper">
                                <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        } @else {
                            <app-production-response [response]="productionResponse" [showResponse]="showProdResponse"></app-production-response>
                        }                        
                    </div>
                    <ng-template #noExample><div class="no-example p-3">No examples available to display. Please update the contract to view static responses.</div></ng-template>
                </div>
            </tab>
        </tabset>
    </div>
    <ng-template #noExample><div class="no-example p-3">Invalid contract</div></ng-template>
</ng-container>

