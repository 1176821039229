import { Component, Input } from '@angular/core';
import { FileOperationsService } from '@app/services/file-operations/file-operations.service';
import { SpeedPostContentModel } from '@app/models/speedpost-data.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  constructor(
    private fileOperationService: FileOperationsService,
  ){}

  @Input() contractDetails: SpeedPostContentModel = null as unknown as SpeedPostContentModel;

  onDownloadContract() {
    if(this.contractDetails && this.contractDetails.fileContent) {
      this.fileOperationService.downloadFile(this.contractDetails.apiName + '.' + this.contractDetails.fileExtension, this.contractDetails.contractType, this.contractDetails.fileContent);
    }
  }
}
